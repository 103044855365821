import React from 'react';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, ComposedChart, ResponsiveContainer} from 'recharts';

function Rsi(props) {
    const { candles } = props;
    const data = candles.map((candle, index) => {
        return {rsi: parseInt(candle.rsi), sell: 70, buy:20}
    });

    return (
        <ComposedChart width={150} height={120} data={data} margin={{top: 10,right: 0,bottom: 0,left: 0}} >
            <CartesianGrid strokeDasharray="3 3" />
            <YAxis interval="preserveEnd" tick={{fontSize: 10}} domain={[0, 100]} />
            <XAxis tick={{fontSize: 10}} />
            <Tooltip />
                <Line type="monotone" dataKey="rsi" stroke="#1281ca" activeDot={{ r: 8 }} />
                <Line type="monotone" dataKey="sell" stroke="#e91e63" />
                <Line type="monotone" dataKey="buy" stroke="#0ab300" />
        </ComposedChart>
    );
}

export default Rsi;