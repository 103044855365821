import React from 'react';
// import '../node_modules/react-vis/dist/style.css';
import {XYPlot, XAxis, YAxis, LineSeries} from 'react-vis';


function SAR(props) {
    const { candles } = props;
    // const data = candles.map((candle, index) => {
    //     return {x:parseFloat(candle.close), u: parseFloat(candle.BBandsUpper), m: parseFloat(candle.bbandsMiddle), l: parseFloat(candle.bbandsLower), x: index}
    // });
    const sar = candles.map((candle, index) => ({y:parseFloat(candle.sar), x: index}));
    
    const c = candles.map((candle, index) => ({y:parseFloat(candle.close), x: index}));
    return (
    <XYPlot xType="linear" width={120} height={120}  margin={{left: 45, right: 10, top:20, bottom:40}}>
        <XAxis />
        <YAxis />
        <LineSeries data={sar} text="ema50"/>
        <LineSeries data={c}/>

    </XYPlot>
    );
}

export default SAR;