import React from 'react';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer, ComposedChart} from 'recharts';

function Srsi(props) {
    const { candles } = props;
    const data = candles.map((candle, index) => {
        return {K: parseInt(candle.srsiOutFastK),D: parseInt(candle.srsiOutFastD), sell: 80, buy:20}
    });

    return (
        <ComposedChart width={150} height={120} data={data} margin={{top: 10,right: 20,bottom: 0,left: 0}} >
            <CartesianGrid strokeDasharray="3 3" />
            <YAxis interval="preserveEnd" tick={{fontSize: 10}} domain={[0, 100]} />
            <XAxis tick={{fontSize: 10}} />
            <Tooltip />
            <Line type="monotone" dataKey="K" stroke="#0088ff" activeDot={{ r: 8 }}  />
            <Line type="monotone" dataKey="D" stroke="#8bc34a" activeDot={{ r: 8 }} />
            <Line type="monotone" dataKey="sell" stroke="#e91e63" />
            <Line type="monotone" dataKey="buy" stroke="#0ab300" />
        </ComposedChart>
        
    );
}

export default Srsi;