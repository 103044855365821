import React from 'react';
// import '../node_modules/react-vis/dist/style.css';
import {XYPlot, XAxis, YAxis, LineSeries} from 'react-vis';


function BB(props) {
    const { candles } = props;
    // const data = candles.map((candle, index) => {
    //     return {x:parseFloat(candle.close), u: parseFloat(candle.BBandsUpper), m: parseFloat(candle.bbandsMiddle), l: parseFloat(candle.bbandsLower), x: index}
    // });
    const u = candles.map((candle, index) => ({y:parseFloat(candle.bbandsUpper), x: index}));
    const l = candles.map((candle, index) => ({y:parseFloat(candle.bbandsLower), x: index}));
    const m = candles.map((candle, index) => ({y:parseFloat(candle.bbandsMiddle), x: index}));
    const c = candles.map((candle, index) => ({y:parseFloat(candle.close), x: index}));
    return (
    <XYPlot xType="linear" width={120} height={120}  margin={{left: 45, right: 10, top:20, bottom:40}}>
      <XAxis />
      <YAxis />
      <LineSeries data={u}/>
      <LineSeries data={c}/>
      <LineSeries data={m}/>
      <LineSeries data={l}/>
    </XYPlot>
    );
}

export default BB;