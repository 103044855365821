import React, { Component, PureComponent } from 'react';
import socketIOClient from "socket.io-client";
import moment from 'moment';
import _ from 'lodash';
import { calculateRSI } from './calculate';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend } from 'recharts';
import {
    BrowserRouter as Router,
    Switch,
    Route,
    Link
  } from "react-router-dom";
import Rsi from './chart/Rsi';
import Macd from './chart/Macd';
import Srsi from './chart/Srsi';
import Ema from './chart/Ema';
import Bb from './chart/Bb';
import BB from './graph/BB';
import EMA from './graph/EMA';
import Sar from './chart/Sar';
import SupRes from './graph/BB';

import './graph/style.css';
import SAR from './graph/SAR';
import MACD from './graph/MACD';
import Adx from './chart/Adx';
import ADX from './graph/ADX';


class Main extends PureComponent {
  constructor() {
    super();
 
    // state değişkenlerimizde hızı, sıcaklığı, devri ve endpoint adresini tutuyoruz
    this.state = {
      loading: true,
      coinDatas: [],
      endpoint: "http://localhost:3001",
    };
  
  }

  componentDidMount() {
    const { endpoint } = this.state;
    const socket = socketIOClient(endpoint);
    socket.on("coinDatas", coinDatas => {
      coinDatas = _.orderBy(coinDatas, ['symbol'], ['asc']);
      this.setState({coinDatas: coinDatas, loading: false});
    })
  }

  getClass = (status) => {
    if(status == 'overbuy'){
      return 'bg-green-400';
    }else if(status == 'oversell'){
      return 'bg-red-400';
    }else if(status == 'buy'){
      return 'bg-green-200';
    }else if(status == 'weakbuy'){
        return 'bg-green-100';
    }else if(status == 'sell'){
      return 'bg-red-200';
    }else if(status == 'weaksell'){
        return 'bg-red-100';
    }else if(status == 'over'){
      return 'bg-red-400';
    }else if(status == 'weak'){
      return 'bg-red-200';
    }else{
      return 'bg-gray-100';
    }
    
  }

  render() {
    if (this.state.loading == true) {
			return <div>Loading...</div>
		}
		return (
			<div> 
       
          {this.state.coinDatas.map((periods, index) => {

              return <div key={index}>
                <div className='grid grid-cols-18 gap-1 bg-gray-100 border-b mt-2'>
                    <div>Symbol</div>
                    <div>Time</div>
                    <div>Close</div>
                    <div>Resistance</div>
                    <div>Support</div>
                    <div>RSI</div>
                    <div>outMACD</div>
                    <div>outMACDSignal</div>
                    <div>outMACDHist</div>
                    <div>srsiOutFastK</div>
                    <div>srsiOutFastD</div>
                    <div>ema50</div>
                    <div>ema100</div>
                    <div>ema150</div>
                    <div>bbandsUpper</div>
                    <div>bbandsMiddle</div>
                    <div>bbandsLower</div>
                    <div>sar</div>
                  </div>
              {periods.candles.map((candle, candleIndex) => {
                return (                        
                  <div className='grid grid-cols-18 gap-1' key={'candle' +index + candleIndex}>
                    <div>{candle.symbol}
                    <br/>Puan: {candle.puan} | {candle.bbPercent}</div>
                    <div>{moment(candle.date).format('HH:mm:ss')} |{periods.period}</div>
                    <div>{candle.close}<br/>
                      s1:{candle.support1.toFixed(5)} | s2:{candle.support2.toFixed(5)} <br/>
                      r1:{candle.resistance1.toFixed(5)} | r2:{candle.resistance2.toFixed(5)}
                    </div>
                    <div>r1:{candle.wr1.toFixed(5)}<br/>r2:{candle.wr2.toFixed(5)}</div>
                    <div>s1:{candle.ws1.toFixed(5)}<br/>s2:{candle.ws2.toFixed(5)}</div>
                    <div className={this.getClass(candle.rsiStatus)}>{candle.rsi.toFixed(3)} - {candle.rsiStatus}</div>
                    <div  className={this.getClass(candle.macdStatus)}>{candle.outMACD.toFixed(5)} - {candle.macdStatus}<br/>
                    check: {(Math.abs(candle.outMACD) - Math.abs(candle.outMACDSignal)).toFixed(5)}
                    </div>
                    <div>{candle.outMACDSignal.toFixed(5)} - {candle.macdStatus}</div>
                    <div>{candle.outMACDHist.toFixed(5)} - {candle.macdStatus}</div>
                    
                    <div className={this.getClass(candle.srsiStatus)}>{candle.srsiOutFastK.toFixed(2)}  - {candle.srsiStatus}</div>
                    <div className={this.getClass(candle.srsiStatus)}>{candle.srsiOutFastD.toFixed(2)}  - {candle.srsiStatus}</div>
                    <div>{candle.ema50.toFixed(3)} - </div>
                    <div>{candle.ema100.toFixed(3)} - </div>
                    <div>{candle.ema150.toFixed(3)} - </div>
                    <div className={this.getClass(candle.bbStatus)}>{candle.bbandsUpper.toFixed(3)} - {candle.bbStatus}</div>
                    <div>{candle.bbandsMiddle.toFixed(3)}</div>
                    <div >{candle.bbandsLower.toFixed(3)}</div>
                    <div>{candle.sar.toFixed(3)}</div>
                  </div>
                  )
              })}
              
               <div className='flex gap-1 bg-gray-50 border-b mt-2'>
                <div>
                    {periods.candles[0].symbol} - {periods.candles[0].period}
                    <div className='flex'>
                        <div className=' text-xl rounded-full border bg-slate-200 flex justify-center items-center'>{periods.candles[periods.candles.length - 1].close}</div>
                        <div className='w-10 h-10 text-xl rounded-full border bg-slate-200 flex justify-center items-center'>{periods.candles[periods.candles.length - 1].puan}</div>
                    </div>
                </div>
                <div>
                  <div>RSI</div>
                  <Rsi candles={periods.candles} />
                  <div className={this.getClass(periods.candles[periods.candles.length-1].rsiStatus)}>
                    {periods.candles[periods.candles.length-1].rsiStatus}
                  </div>
                </div>
                <div>
                  <div>StochRSI</div>
                  <Srsi  candles={periods.candles} />
                  <div className={this.getClass(periods.candles[periods.candles.length-1].srsiStatus)}>
                    {periods.candles[periods.candles.length-1].srsiStatus}
                  </div>
                </div>
                <div>
                  <div>MACD</div>
                  <Macd candles={periods.candles} />
                  <div className={this.getClass(periods.candles[periods.candles.length-1].macdStatus)}>
                    {periods.candles[periods.candles.length-1].macdStatus}
                  </div>
                </div>
                <div>
                  <div>EMA</div>
                  <EMA candles={periods.candles} />
                  <div className={this.getClass(periods.candles[periods.candles.length-1].emaStatus)}>
                    {periods.candles[periods.candles.length-1].emaStatus}
                  </div>
                </div>
                <div>
                  <div>ADX</div>
                  <ADX candles={periods.candles} />
                  <div className={this.getClass(periods.candles[periods.candles.length-1].adxStatus)}>
                    {periods.candles[periods.candles.length-1].adxStatus}
                  </div>
                </div>

                <div>Bolinger<BB candles={periods.candles} />
                  <div className={this.getClass(periods.candles[periods.candles.length-1].bbStatus)}>
                    {periods.candles[periods.candles.length-1].bbStatus}
                  </div>
                </div>
                <div>SAR<SAR candles={periods.candles} /> </div>
              </div> 
            </div>
          })}
      </div>
		)
   
  }
}
 
export default Main;
