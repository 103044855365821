import React from 'react';
// import '../node_modules/react-vis/dist/style.css';
import {XYPlot, XAxis, YAxis, LineSeries} from 'react-vis';


function EMA(props) {
    const { candles } = props;
    // const data = candles.map((candle, index) => {
    //     return {x:parseFloat(candle.close), u: parseFloat(candle.BBandsUpper), m: parseFloat(candle.bbandsMiddle), l: parseFloat(candle.bbandsLower), x: index}
    // });
    const ema50 = candles.map((candle, index) => ({y:parseFloat(candle.ema50), x: index}));
    const ema100 = candles.map((candle, index) => ({y:parseFloat(candle.ema100), x: index}));
    const ema150 = candles.map((candle, index) => ({y:parseFloat(candle.ema150), x: index}));
    const c = candles.map((candle, index) => ({y:parseFloat(candle.close), x: index}));
    return (
    <XYPlot xType="linear" width={120} height={120}  margin={{left: 45, right: 10, top:20, bottom:40}}>
        <XAxis />
        <YAxis />
        <LineSeries data={ema50} text="ema50"/>
        <LineSeries data={c}/>
        <LineSeries data={ema100}/>
        <LineSeries data={ema150}/>
    </XYPlot>
    );
}

export default EMA;