import React, { Component } from 'react'
import socketIOClient from "socket.io-client";
import moment from 'moment';
import _ from 'lodash';
class Trades extends Component {
    constructor() {
        super();
     
        // state değişkenlerimizde hızı, sıcaklığı, devri ve endpoint adresini tutuyoruz
        this.state = {
            loading: true,
            df_longs: [],
            df_shorts: [],
            df_long_closeds: [],
            df_short_closeds: [],
            coinDatas: [],
            totalMany:0,
            profit:0,
            crossUnPnl: 0.0,
            frontProfit:0,
            endpoint: "https://server.yanakadam.com",
            // endpoint: "http://localhost:3001",
            buyPrice: 0,
            sellPrice: 0,
            percent:{}
        };
        const { endpoint } = this.state;
        this.socket = socketIOClient(
            // "https://server.yanakadam.com",
            this.state.endpoint, 
            {transports: ['websocket'], reconnection: true, reconnectionDelay: 10000, reconnectionDelayMax: 20000, reconnectionAttempts: Infinity});
    
    }

    componentDidMount() {
        // const { endpoint } = this.state;
        // const socket = socketIOClient(endpoint);
        this.socket.on("trades", trades => {
            // console.log(trades);
            var df_longs = trades.df_longs;
            var df_shorts = trades.df_shorts
            var df_long_closeds = _.orderBy(trades.df_long_closeds, ['step2_date'], ['desc']);
            var df_short_closeds = _.orderBy(trades.df_short_closeds, ['step2_date'], ['desc']);
            // df_sell.reverse();

            var frontProfit = 0;
            // df_longs.forEach(function(item, index){
            //     if(item.status == 'closed'){
            //         frontProfit += item.profit;
            //     }
                
            // });
            // df_sell.forEach(function(item, index){
            //     if(item.status == 'closed'){
            //         frontProfit += item.profit;
            //     }
            // });

            this.setState({ wallet:trades.wallet, profit:trades.profit, frontProfit, totalMany: trades.totalMany, crossUnPnl: trades.crossUnPnl, loading: false, percent: trades.percent, df_longs, df_shorts, df_long_closeds, df_short_closeds });
        })

        
        this.socket.on("coinDatas", coinDatas => {
            // this.setState({buyPrice: coinDatas[0].candles[coinDatas[0].candles.length-1].close})
            // this.setState({sellPrice: coinDatas[0].candles[coinDatas[0].candles.length-1].close})
            // console.log('coinDatas', coinDatas)
            coinDatas = _.orderBy(coinDatas, ['symbol'], ['asc']);
            this.setState({coinDatas: coinDatas});
        })
    }

    getClass = (status) => {
        if(status == 'overbuy'){
          return 'bg-green-400';
        }else if(status == 'oversell'){
          return 'bg-red-400';
        }else if(status == 'buy'){
          return 'bg-green-200';
        }else if(status == 'weakbuy'){
            return 'bg-green-100';
        }else if(status == 'sell'){
          return 'bg-red-200';
        }else if(status == 'weaksell'){
            return 'bg-red-100';
        }else if(status == 'over'){
          return 'bg-red-400';
        }else if(status == 'weak'){
          return 'bg-red-200';
        }else{
          return 'bg-gray-100';
        }    
    }

      
    render() {
        return (
            <div className="flex flex-col">
                {this.state.loading == true ?
                (<div className="-my-2  sm:-mx-6 lg:-mx-8">Yükleniyor...</div>):
                (<div className="-my-2  sm:-mx-6 lg:-mx-8">
                    <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
                        <div className='text-md mt-5 grid grid-cols-2 w-1/4'>
                            <div>Total Percent: </div><div>{this.state.percent.total_percent.toFixed(2)}%</div>
                            <div>Long Percent: </div>{this.state.percent.long_cum_percent&&<div>{this.state.percent.long_cum_percent.toFixed(2)}% {(this.state.percent.long_cum_percent*50).toFixed(2)}%</div>}
                            <div>Short Percent: </div>{this.state.percent.short_cum_percent&&<div>{this.state.percent.short_cum_percent.toFixed(2)}% {(this.state.percent.short_cum_percent*50).toFixed(2)}%</div>}
                        </div>
                        <div className='text-md mt-5 grid grid-cols-2 w-1/4'>
                            <div>Total PNL: </div><div>{this.state.crossUnPnl.toFixed(2)}$</div>
                            <div>Free Money: </div><div>{(this.state.totalMany).toFixed(2)}$ </div>
                            <div>Wallet: </div><div>{(this.state.wallet).toFixed(2)}$ </div>
                        </div>
                        <div className='sm:grid-cols-2 mt-5 grid grid-cols-1 gap-10 w-full text-md'>
                            <div className='	'>
                                <div className='text-md mt-5'>
                                    OPEN LONGS ({this.state.df_longs.length})
                                </div>
                                <div className="shadow  border-b border-gray-200 sm:rounded-lg">
                                    <table className="min-w-full divide-y divide-gray-200">
                                        <thead className="bg-gray-50">
                                            <tr className="border">
                                                <th className="border-r py-2 text-md">Date</th>
                                                <th className="border-r py-2 text-md">orderId</th>
                                                <th className="border-r py-2 text-md">Status</th>
                                                <th className="border-r py-2 text-md">Price</th>
                                                <th className="border-r py-2 text-md">Profit</th>
                                                <th className="border-r py-2 text-md">%</th>
                                                <th className="border-r py-2 text-md">Max %</th>
                                                <th className="border-r py-2 text-md">SP%</th>
                                                <th className="border-r py-2 text-md">EMA</th>
                                                <th className="border-r py-2 text-md">QTY</th>
                                                <th className="border-r py-2 text-md">SELL</th>
                                            </tr>
                                        </thead>
                                        <tbody className="bg-white divide-y divide-gray-200" style={{'font-family': 'monospace'}}>
                                            {this.state.df_longs.map((trade, index) => {
                                                return(
                                                    <tr key={index} className='border'>
                                                        <td className='border-r'>{new Date(trade.step1_open_date*1000).toLocaleString('tr-TR')}</td>
                                                        <td className='border-r'>{trade.step1_orderId}</td>
                                                        <td className='border-r'>{trade.step1_status}</td>
                                                        <td className='border-r'>{trade.step1_price}</td>
                                                        <td className='border-r'>{parseFloat(trade.profit).toFixed(2)}</td>
                                                        <td className='border-r'>{parseFloat(trade.percent).toFixed(2)}</td>
                                                        <td className='border-r'>{parseFloat(trade.percent_max).toFixed(2)}</td>
                                                        <td className='border-r'>{trade.sell_percent.toFixed(2)}</td>
                                                        <td className='border-r'>{trade.step1_ema_percent.toFixed(2)}</td>
                                                        <td className='border-r'>{trade.step1_executedQty}</td>
                                                        <td className='border-r flex justify-end '>
                                                            <div className='border text-red-700 px-0.5 rounded-md border-red-300 my-1 w-24 text-center bg-red-200 hover:bg-red-300 cursor-pointer' 
                                                                onClick={() => {
                                                                    this.socket.emit('close_long', {symbol: trade.symbol, id:trade.id , period:trade.period, price:0, quantity: parseFloat(trade.step1_executedQty)})
                                                                }}>KAPAT</div>
                                                        </td>
                                                    </tr>
                                                )
                                            })}     
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                            <div className='	'>
                                <div className=' mt-5'>
                                    OPEN SHORTS ({this.state.df_shorts.length})
                                </div>
                                <div className="shadow  border-b border-gray-200 sm:rounded-lg">
                                    <table className="min-w-full divide-y divide-gray-200">
                                        <thead className="bg-gray-50">
                                            <tr className="border">
                                            <th className="border-r py-2 text-md">Date</th>
                                                <th className="border-r py-2 text-md">orderId</th>
                                                <th className="border-r py-2 text-md">Status</th>
                                                <th className="border-r py-2 text-md">Price</th>
                                                <th className="border-r py-2 text-md">Profit</th>
                                                <th className="border-r py-2 text-md"> %</th>
                                                <th className="border-r py-2 text-md">Max %</th>
                                                <th className="border-r py-2 text-md">SP %</th>
                                                <th className="border-r py-2 text-md">EMA</th>
                                                <th className="border-r py-2 text-md">QTY</th>
                                                <th className="border-r py-2 text-md">SELL</th>
                                            </tr>
                                        </thead>
                                        <tbody className="bg-white divide-y divide-gray-200" style={{'font-family': 'monospace'}}>
                                            {this.state.df_shorts.map((trade, index) => {
                                                return(
                                                    <tr key={index} className='border'>
                                                        <td className='border-r'>{new Date(trade.step1_open_date*1000).toLocaleString('tr-TR')} - {new Date(trade.step2_close_date*1000).toLocaleTimeString('tr-TR')}</td>
                                                        <td className='border-r'>{trade.step1_orderId}</td>
                                                        <td className='border-r'>{trade.step1_status}</td>
                                                        <td className='border-r'>{trade.step1_price}</td>
                                                        <td className='border-r'>{parseFloat(trade.profit).toFixed(2)}</td>
                                                        <td className='border-r'>{parseFloat(trade.percent).toFixed(2)}</td>
                                                        <td className='border-r'>{parseFloat(trade.percent_max).toFixed(2)}</td>
                                                        <td className='border-r'>{trade.sell_percent.toFixed(2)}</td>
                                                        <td className='border-r'>{trade.step1_ema_percent.toFixed(2)}</td>
                                                        <td className='border-r'>{trade.step1_executedQty}</td>
                                                        <td className='border-r flex justify-end '>
                                                            <div className='border text-red-700 px-0.5 rounded-md border-red-300 my-1 w-24 text-center bg-red-200 hover:bg-red-300 cursor-pointer' 
                                                                onClick={() => {
                                                                    this.socket.emit('close_short', {symbol: trade.symbol, id:trade.id , period:trade.period, price:0, quantity: parseFloat(trade.step1_executedQty)})
                                                                }}>KAPAT</div>
                                                        </td>
                                                    </tr>
                                                )
                                            })}     
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                        <div className='sm:grid-cols-2 mt-5 grid grid-cols-1 gap-10 w-full text-md'>
                            <div>
                                <div className='text-md mt-5'>
                                    CLOSED LONGS ({this.state.df_long_closeds.length})
                                </div>
                                <div className="shadow  border-b border-gray-200 sm:rounded-lg">
                                    <table className="min-w-full divide-y divide-gray-200">
                                        <thead className="bg-gray-50">
                                            <tr className="border">
                                            <th className="border-r py-2 text-md">Date</th>
                                                <th className="border-r py-2 text-md">orderId</th>
                                                <th className="border-r py-2 text-md">Status</th>
                                                <th className="border-r py-2 text-md">Buy</th>
                                                <th className="border-r py-2 text-md">Sell</th>
                                                <th className="border-r py-2 text-md">Profit</th>
                                                <th className="border-r py-2 text-md">Profit %</th>
                                                <th className="border-r py-2 text-md">QTY</th>
                                                <th className="border-r py-2 text-md">EMA</th>
                                            </tr>
                                        </thead>
                                        <tbody className="bg-white divide-y divide-gray-200" style={{'font-family': 'monospace'}}>
                                            {this.state.df_long_closeds.map((trade, index) => {
                                                return(
                                                    <tr key={index} className='border'>
                                                        <td className='border-r'>{new Date(trade.step1_open_date*1000).toLocaleString('tr-TR')} - {new Date(trade.step2_close_date*1000).toLocaleTimeString('tr-TR')}</td>
                                                        <td className='border-r'>{trade.step1_orderId}</td>
                                                        <td className='border-r'>{trade.step1_status}</td>
                                                        <td className='border-r'>{trade.step1_price.toFixed(1)}</td>
                                                        <td className='border-r'>{trade.step2_status == "FILLED"?trade.step2_price.toFixed(1):'-'}</td>
                                                        <td className='border-r'>{parseFloat(trade.profit).toFixed(2)}</td>
                                                        <td className='border-r'>{parseFloat(trade.percent).toFixed(2)}</td>
                                                        <td className='border-r'>{trade.step1_executedQty}</td>
                                                        <td className='border-r'>{parseFloat(trade.ema_percent).toFixed(2)}</td>
                                                       
                                                    </tr>
                                                )
                                            })}     
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                            <div>
                                <div className=' mt-5'>
                                    CLOSED SHORTS ({this.state.df_short_closeds.length})
                                </div>
                                <div className="shadow  border-b border-gray-200 sm:rounded-lg">
                                    <table className="min-w-full divide-y divide-gray-200">
                                        <thead className="bg-gray-50">
                                            <tr className="border">
                                                <th className="border-r py-2 text-md">Date</th>
                                                <th className="border-r py-2 text-md">orderId</th>
                                                <th className="border-r py-2 text-md">Status</th>
                                                <th className="border-r py-2 text-md">Buy</th>
                                                <th className="border-r py-2 text-md">Sell</th>
                                                <th className="border-r py-2 text-md">Profit</th>
                                                <th className="border-r py-2 text-md">Profit %</th>
                                                <th className="border-r py-2 text-md">QTY</th>
                                                <th className="border-r py-2 text-md">EMA</th>

                                            </tr>
                                        </thead>
                                        <tbody className="bg-white divide-y divide-gray-200" style={{'font-family': 'monospace'}}>
                                            {this.state.df_short_closeds.map((trade, index) => {
                                                return(
                                                    <tr key={index} className='border'>
                                                        <td className='border-r'>{new Date(trade.step1_open_date*1000).toLocaleString('tr-TR')}</td>
                                                        <td className='border-r'>{trade.step1_orderId}</td>
                                                        <td className='border-r'>{trade.step1_status}</td>
                                                        <td className='border-r'>{trade.step1_price.toFixed(1)}</td>
                                                        <td className='border-r'>{trade.step2_status == "FILLED"?trade.step2_price.toFixed(1):'-'}</td>
                                                        <td className='border-r'>{parseFloat(trade.profit).toFixed(2)}</td>
                                                        <td className='border-r'>{parseFloat(trade.percent).toFixed(2)}</td>
                                                        <td className='border-r'>{trade.step1_executedQty}</td>
                                                        <td className='border-r'>{parseFloat(trade.ema_percent).toFixed(2)}</td>
                                                    </tr>
                                                )
                                            })}     
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                        <div className=' mt-5'>
                            Coin Datas 
                        </div>
                        
                        <div className="shadow overflow-x-auto	 border-b border-gray-200 sm:rounded-lg">
                            <table className="min-w-full divide-y divide-gray-200 ">
                                <thead className="bg-gray-50">
                                    <tr className="border">
                                        <th className="border-r py-2">Symbol</th>
                                        <th className="border-r py-2">Time</th>
                                        <th className="border-r py-2">Close</th>
                                        <th className="border-r py-2">EMA</th>
                                        <th className="border-r py-2">i[-1]</th>
                                        <th className="border-r py-2">i</th>
                                        <th className="border-r py-2">WT1</th>
                                        <th className="border-r py-2">WT2</th>
                                        <th className="border-r py-2"></th>
                                        <th className="border-r py-2  w-32">Bias</th>
                                    </tr>
                                </thead>
                                <tbody className="bg-white divide-y divide-gray-200">
                                    {this.state.coinDatas.map((coin, index) => {
                                        var candle = coin.candles[coin.candles.length - 1];
                                        // if(candle.bbPercent < 10){
                                           
                                        return(
                                            
                                            <tr key={index} className='border' style={{'font-family': 'monospace'}}>

                                                <td className='border-r'>{candle.symbol}</td>
                                                <td className='border-r'>{candle.date} | {candle.period}</td>
                                                <td className='border-r'>{candle.close.toFixed(1)}</td>
                                                <td className='border-r'>{candle.ema_percent.toFixed(1)}</td>
                                                <td className='border-r'>{(coin.candles[coin.candles.length - 2].area_wt1_w2)}</td>
                                                <td className='border-r'>{(coin.candles[coin.candles.length - 1].area_wt1_w2)}</td>
                                                <td className='border-r'>{candle.wt1}</td>
                                                <td className='border-r'>{candle.wt2}</td>
                                                <td className='border-r'>
                                                    {Math.fround(coin.candles[coin.candles.length - 4].wt1) > Math.fround(coin.candles[coin.candles.length - 4].wt2) ?'LONG': 'SHORT'} | 
                                                    {Math.fround(coin.candles[coin.candles.length - 3].wt1) > Math.fround(coin.candles[coin.candles.length - 3].wt2) ?'LONG': 'SHORT'} |
                                                    {Math.fround(coin.candles[coin.candles.length - 2].wt1) > Math.fround(coin.candles[coin.candles.length - 2].wt2) ?'LONG': 'SHORT'} |
                                                    {Math.fround(coin.candles[coin.candles.length - 1].wt1) > Math.fround(coin.candles[coin.candles.length - 1].wt2) ?'LONG': 'SHORT'} |
                                                </td>
                                                <td className='border-r flex justify-end w-32'>
                                                    <div className='border border-green-300 text-green-800 px-0.5 rounded-md my-1 w-24 text-center bg-green-200 hover:bg-green-300 cursor-pointer' 
                                                        onClick={() => {
                                                            this.socket.emit('buy', {symbol: candle.symbol, period:candle.period, price:this.state.buyPrice, side: 'BUY'})
                                                        }}>LONG</div>
                                                </td>
                                                <td className='border-r flex justify-end w-32'>
                                                    <div className='border border-green-300 text-green-800 px-0.5 rounded-md my-1 w-24 text-center bg-green-200 hover:bg-green-300 cursor-pointer' 
                                                        onClick={() => {
                                                            this.socket.emit('buy', {symbol: candle.symbol, period:candle.period, price:this.state.buyPrice, side:'SELL'})
                                                        }}>SHORT</div>
                                                </td>
                                            </tr>
                                        )
                                            
                                            // }
                                    })}     
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>)}
            </div>
        )
    }
}
export default Trades;