import React, { Component } from 'react'
import socketIOClient from "socket.io-client";
import moment from 'moment';
import _ from 'lodash';
import '/node_modules/@hawk-ui/progress-bar/dist/index.min.css'
import ApexCharts from 'apexcharts'

import ProgressBar from '@hawk-ui/progress-bar';

class Trades extends Component {
    constructor() {
        super();
     
        // state değişkenlerimizde hızı, sıcaklığı, devri ve endpoint adresini tutuyoruz
        this.state = {
            loading: true,
            df_longs: [],
            df_shorts: [],
            df_long_closeds: [],
            df_short_closeds: [],
            coinDatas: [],
            totalMany:0,
            profit:0,
            crossUnPnl: 0.0,
            frontProfit:0,
            // endpoint: "https://makine.yanakadam.com",
            endpoint: "http://localhost:3006",
            buyPrice: 0,
            sellPrice: 0,
            percent:{},
            minMaxData: [],
            status: {},
            kar_long: 0,
            grid_data: {}
        };
        const { endpoint } = this.state;
        this.socket = socketIOClient(
            // "https://server.yanakadam.com",
            this.state.endpoint, 
            {transports: ['websocket'], reconnection: true, reconnectionDelay: 1000, reconnectionDelayMax: 2000, reconnectionAttempts: Infinity}
            );
        

    
    }

    componentDidMount() {

        // const { endpoint } = this.state;
        // const socket = socketIOClient(endpoint);
        // this.socket.on("trades", trades => {
        //     // console.log(trades.positions);
        //     var df_longs = trades.df_longs;
        //     var df_shorts = trades.df_shorts
        //     var status = trades.status;
        //     var df_long_closeds = _.orderBy(trades.df_long_closeds, ['step2_date'], ['desc']);
        //     var df_short_closeds = _.orderBy(trades.df_short_closeds, ['step2_date'], ['desc']);

        //     var frontProfit = 0;


        //     this.setState({ wallet:trades.wallet, profit:trades.profit, frontProfit, totalMany: trades.totalMany, crossUnPnl: trades.crossUnPnl, loading: false, percent: trades.percent, df_longs, df_shorts, df_long_closeds, df_short_closeds, status });
        // }) 

        
        this.socket.on("coinDatas", coinDatas => {
            // this.setState({buyPrice: coinDatas[0].candles[coinDatas[0].candles.length-1].close})
            // this.setState({sellPrice: coinDatas[0].candles[coinDatas[0].candles.length-1].close})
            this.trades = coinDatas.trades
            // console.log('oinDatas.df_coin_datas', coinDatas.df_coin_datas) 
            this.setState({coinDatas: coinDatas.df_coin_datas});
            this.setState({grid_data: {
                up_price: this.trades.percent.up_price,
                down_price: this.trades.percent.down_price,
                level_current: this.trades.percent.level_current,
                old_level: this.trades.percent.old_level,
                start_price: this.trades.percent.start_price,
            }})
            
            var df_longs = this.trades.df_longs;
            var df_shorts = this.trades.df_shorts
            var df_longs = _.orderBy(this.trades.df_longs, ['symbol'], ['asc']);
            var df_shorts = _.orderBy(this.trades.df_shorts, ['symbol'], ['asc']);

            var status = this.trades.status;
            var df_long_closeds = _.orderBy(this.trades.df_long_closeds, ['step2_close_date'], ['asc']);
            var df_short_closeds = _.orderBy(this.trades.df_short_closeds, ['step2_close_date'], ['asc']);
            // console.log(df_short_closeds)
            var df_long_closeds = this.trades.df_long_closeds;
            var df_short_closeds = this.trades.df_short_closeds;
            var kar_long = 0;
            df_long_closeds.map((trade) => {
                kar_long += (trade.step1_price * trade.percent/100 * trade.step1_executedQty) - Math.abs(trade.step1_price * trade.percent/100 * trade.step1_executedQty) * 0.08
            })
            var kar_short = 0;
            df_short_closeds.map((trade) => {
                kar_short += (trade.step1_price * trade.percent/100 * trade.step1_executedQty) - Math.abs(trade.step1_price * trade.percent/100 * trade.step1_executedQty) * 0.08
            })
            this.setState({ wallet:this.trades.wallet, profit:this.trades.profit, totalMany: this.trades.totalMany, crossUnPnl: this.trades.crossUnPnl, loading: false, percent: this.trades.percent, df_longs, df_shorts, df_long_closeds, df_short_closeds, status, kar_long, kar_short });
        })
    }

    getClass = (status) => {
        if(status == 'overbuy'){
          return 'bg-green-400';
        }else if(status == 'oversell'){
          return 'bg-red-400';
        }else if(status == 'buy'){
          return 'bg-green-200';
        }else if(status == 'weakbuy'){
            return 'bg-green-100';
        }else if(status == 'sell'){
          return 'bg-red-200';
        }else if(status == 'weaksell'){
            return 'bg-red-100';
        }else if(status == 'over'){
          return 'bg-red-400';
        }else if(status == 'weak'){
          return 'bg-red-200';
        }else{
          return 'bg-gray-100';
        }    
    }

      
    render() {
        return (
            <div className="flex flex-col">
                {this.state.loading == true ?
                (<div className="-my-2  sm:-mx-6 lg:-mx-8">Yükleniyor...</div>):
                (<div className="-my-2  sm:-mx-6 lg:-mx-8">
                    <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
                        {/* <div className='text-md mt-5 grid grid-cols-2 w-1/4'>
                            <div>Long TP: </div><div>{this.state.percent.long_tp.toFixed(2)}</div>
                            <div>Long Price: </div><div>{this.state.percent.long_price.toFixed(2)}</div>
                            <div>Short Price: </div><div>{this.state.percent.short_price.toFixed(2)}</div>
                            <div>Short TP: </div><div>{this.state.percent.short_tp.toFixed(2)}</div>
                            
                            
                        </div> */}
                        <div className='text-md mt-5 grid grid-cols-2 w-1/4'>
                            <div>Total PNL: </div><div>{this.state.crossUnPnl.toFixed(2)}$</div>
                            <div>Free Money: </div><div>{(this.state.totalMany).toFixed(2)}$ </div>
                            <div>Yükseliş Kar: </div><div>{(this.state.kar_long).toFixed(2)}$ </div>
                            <div>Düşüş Kar: </div><div>{(this.state.kar_short).toFixed(2)}$ </div>
                            <div>Wallet: </div><div>{(this.state.wallet).toFixed(2)}$ </div>
                            
                        </div>
                        {/* <div className='sm:grid-cols-1 mt-5 grid grid-cols-1 gap-10 w-full text-md'>
                            <div className='bg-gray-200 ' style={{width:300, height:200, position:'relative'}}>
                                <div className='bg-red-400' style={{width:30, height:20, position:'absolute', bottom:0}}>10%</div>
                            </div>
                        </div> */}
                        <div className=' mt-5'>
                            Coin Datas 
                        </div>
                        
                        <div className="shadow overflow-x-auto	 border-b border-gray-200 sm:rounded-lg">
                            <table className="min-w-full divide-y divide-gray-200 ">
                                <thead className="bg-gray-50">
                                    <tr className="border">
                                        <th className="border-r py-2">Symbol</th>
                                        {/* <th className="border-r py-2">Time</th> */}
                                        <th className="border-r py-2">Close</th>
                                        {/* <th className="border-r py-2">Level</th> */}
                                        <th className="border-r py-2">Status</th>
                                        <th className="border-r py-2">ATR</th>
                                        <th className="border-r py-2">percent</th>
                                        <th className="border-r py-2">outSlowK</th>
                                        <th className="border-r py-2">outSlowD</th>
                                        {/* <th className="border-r py-2">ema50</th>
                                        <th className="border-r py-2">ema200</th> */}
                                        <th className="border-r py-2">i[-1]</th>
                                        <th className="border-r py-2">i</th>
                                        <th className="border-r py-2">RSI</th>
                                        <th className="border-r py-2">WT0</th>
                                        <th className="border-r py-2">WT</th>
                                        <th className="border-r py-2">Ema100</th>
                                        <th className="border-r py-2">Ema50</th>
                                        <th className="border-r py-2  w-32"></th>
                                        <th className="border-r py-2  w-32"></th>
                                    </tr>
                                </thead>
                                <tbody className="bg-white divide-y divide-gray-200">
                                    {this.state.coinDatas.map((coin, index) => {
                                        var candle = coin.candles[coin.candles.length - 1];
                                        // if(candle.bbPercent < 10){
                                           
                                        return(
                                            
                                            <tr key={index} className='border' style={{'font-family': 'monospace'}}>

                                                <td className='border-r'>{candle.symbol}</td>
                                                {/* <td className='border-r'>{candle.date} | {candle.period}</td> */}
                                                <td className='border-r'>{candle.close.toFixed(4)} {candle.period}</td>
                                                {/* <td className='border-r'>{this.state.status[candle.symbol].level_current}</td> */}

                                                <td style={{width:200}}><ProgressBar value={coin.candles[coin.candles.length - 1].vwap_band_percent}  maxRange={100}/></td>
                                                <td className='border-r'>{candle.atr.toFixed(2)}</td>
                                                <td className='border-r'>{candle.vwap_band_percent.toFixed(2)}</td>
                                                <td className={`border-r ${Math.fround(candle.outSlowK) > Math.fround(candle.outSlowD)?' bg-green-200': ' bg-red-200' }`}>{candle.outSlowK.toFixed(2)}</td>
                                                <td className={`border-r ${Math.fround(candle.outSlowK) > Math.fround(candle.outSlowD)?' bg-green-200': ' bg-red-200' }`}>{candle.outSlowD.toFixed(2)}</td>
                                                {/* <td className={`border-r ${
                                                    Math.fround(candle.ema50) > Math.fround(candle.ema200)?' bg-green-200': ' bg-red-200'}`}  >{candle.ema50_close_percent.toFixed(2)}</td>
                                                <td className={`border-r ${
                                                    Math.fround(candle.ema50) > Math.fround(candle.ema200)?' bg-green-200': ' bg-red-200'}`}  >{candle.ema200_close_percent.toFixed(2)}</td>
             */}
                                                <td className='border-r'>{(coin.candles[coin.candles.length - 2].area_wt1_w2)}</td>
                                                <td className='border-r'>{(coin.candles[coin.candles.length - 1].area_wt1_w2)}</td>
                                                <td className={`border-r ${Math.fround(candle.rsi) < Math.fround(50)?' bg-green-200': ' bg-red-200' }`}>{coin.candles[coin.candles.length - 1].rsi.toFixed(2)}|{coin.candles[coin.candles.length - 1].rsi_smooth.toFixed(2)}</td>
                                                <td className='border-r'>wt1:{coin.candles[coin.candles.length - 1].wt1} |  wt2:{coin.candles[coin.candles.length - 1].wt2} </td>
                                                <td className={`border-r ${Math.fround(coin.candles[coin.candles.length - 1].wt1) > Math.fround(coin.candles[coin.candles.length - 2].wt1)?' bg-green-200': ' bg-red-200' }`}>{Math.fround(coin.candles[coin.candles.length - 1].wt1) > Math.fround(coin.candles[coin.candles.length - 2].wt1) ?'LONG': 'SHORT'}</td>
                                                <td className={`border-r ${Math.fround(candle.ema100) > Math.fround(candle.ema200)?' bg-green-200': ' bg-red-200' }`}>{Math.fround(candle.ema100) > Math.fround(candle.ema200)? 'LONG': 'SHORT'}</td>
                                                <td className={`border-r ${Math.fround(candle.ema50) > Math.fround(candle.ema200)?' bg-green-200': ' bg-red-200' }`}>{Math.fround(candle.ema50) > Math.fround(candle.ema200)? 'LONG': 'SHORT'}</td>
                                                <td className='border-r w-32'>
                                                    <div className='border border-green-300 text-green-800 px-0.5 rounded-md my-1 w-24 text-center bg-green-200 hover:bg-green-300 cursor-pointer' 
                                                        onClick={() => {
                                                            this.socket.emit('buy', {symbol: candle.symbol, period:candle.period, price:this.state.buyPrice, side: 'BUY'})
                                                        }}>LONG</div>
                                                </td>
                                                <td className='border-r w-32'>
                                                    <div className='border border-green-300 text-green-800 px-0.5 rounded-md my-1 w-24 text-center bg-green-200 hover:bg-green-300 cursor-pointer' 
                                                        onClick={() => {
                                                            this.socket.emit('buy', {symbol: candle.symbol, period:candle.period, price:this.state.buyPrice, side:'SELL'})
                                                        }}>SHORT</div>
                                                </td>
                                            </tr>
                                        )
                                            
                                            // }
                                    })}     
                                </tbody>
                            </table>
                        </div>
                        <div className='xs:grid-cols-2 mt-5 grid grid-cols-1 gap-10 w-full text-md'>
                            <div className='	'>
                                <div className='text-md mt-5'>
                                    OPEN LONGS ({this.state.df_longs.length})
                                </div>
                                <div className="shadow  border-b border-gray-200 sm:rounded-lg">
                                    <table className="min-w-full divide-y divide-gray-200">
                                        <thead className="bg-gray-50">
                                            <tr className="border">
                                                <th className="border-r py-2 text-md">Symbol</th>
                                                <th className="border-r py-2 text-md">Date</th>
                                                <th className="border-r py-2 text-md">orderId</th>
                                                <th className="border-r py-2 text-md">Status</th>
                                                <th className="border-r py-2 text-md">Price</th>
                                                <th className="border-r py-2 text-md">Level</th>
                                                <th className="border-r py-2 text-md">Kar</th>
                                                <th className="border-r py-2 text-md">Stop %</th>
                                                <th className="border-r py-2 text-md">per %</th>
                                                <th className="border-r py-2 text-md">%</th>
                                                <th className="border-r py-2 text-md">Hedef %</th>
                                                <th className="border-r py-2 text-md">Max %</th>
                                                
                                                
                                                <th className="border-r py-2 text-md">QTY</th>
                                                <th className="border-r py-2 text-md">SELL</th>
                                            </tr>
                                        </thead>
                                        <tbody className="bg-white divide-y divide-gray-200" style={{'font-family': 'monospace'}}>
                                            {this.state.df_longs.map((trade, index) => {
                                                return(
                                                    <tr key={index} className='border'>
                                                        <td className='border-r'>{trade.symbol} </td>
                                                        <td className='border-r'>{new Date(trade.step1_open_date*1000).toLocaleString('tr-TR')}</td>
                                                        <td className='border-r'>{trade.step1_orderId} </td>
                                                        <td className='border-r'>{trade.step1_status}</td>
                                                        <td className='border-r'>{trade.step1_price}</td>
                                                        <td className='border-r'>{trade.level}</td>
                                                        <td className='border-r font-weight-bold'>{(trade.avg_price * trade.percent/100 * trade.step1_executedQty).toFixed(3) } </td>
                                                        <td className='border-r'>{parseFloat(trade.stop_percent).toFixed(2)}</td>
                                                        <td className='border-r'>{parseFloat(trade.per_percent).toFixed(2)}</td>
                                                        <td className={`border-r ${Math.fround(trade.percent) > Math.fround(0.0)?' bg-green-400': ' bg-red-500' }`}>{parseFloat(trade.percent).toFixed(2)} </td>
                                                        <td className='border-r'>{parseFloat(trade.sell_percent).toFixed(2)}</td>
                                                        <td className='border-r'>{parseFloat(trade.percent_max).toFixed(2)}</td>
                                                        <td className='border-r'>{(trade.step1_executedQty * trade.avg_price).toFixed(0)} $</td>

                                                        <td className='border-r flex justify-end '>
                                                            <div class="m-3 boder cursor-pointer" onClick={() => {
                                                                    this.socket.emit('arti', {symbol: trade.symbol, id:trade.step1_orderId , period:trade.period, price:0, quantity: parseFloat(trade.step1_executedQty)})
                                                                }}>+++</div>
                                                            <div class="m-3 boder cursor-pointer" onClick={() => {
                                                                    this.socket.emit('eksi', {symbol: trade.symbol, id:trade.step1_orderId , period:trade.period, price:0, quantity: parseFloat(trade.step1_executedQty)})
                                                                }}>---</div>
                                                            <div className='border text-red-700 px-0.5 rounded-md border-red-300 my-1 w-24 text-center bg-red-200 hover:bg-red-300 cursor-pointer' 
                                                                onClick={() => {
                                                                    this.socket.emit('del_long', {symbol: trade.symbol, id:trade.step1_orderId , period:trade.period, price:0, quantity: parseFloat(trade.step1_executedQty)})
                                                                }}>Sil</div>
                                                            <div className='border text-red-700 px-0.5 rounded-md border-red-300 my-1 w-24 text-center bg-red-200 hover:bg-red-300 cursor-pointer' 
                                                                onClick={() => {
                                                                    this.socket.emit('close_long', {symbol: trade.symbol, id:trade.step1_orderId , period:trade.period, price:0, quantity: parseFloat(trade.step1_executedQty)})
                                                                }}>KAPAT</div>
                                                        </td>
                                                    </tr>
                                                )
                                            })}     
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                            <div className='	'>
                                <div className=' mt-5'>
                                    OPEN SHORTS ({this.state.df_shorts.length})
                                </div>
                                <div className="shadow  border-b border-gray-200 sm:rounded-lg">
                                    <table className="min-w-full divide-y divide-gray-200">
                                        <thead className="bg-gray-50">
                                                <th className="border-r py-2 text-md">Symbol</th>
                                                <th className="border-r py-2 text-md">Date</th>
                                                <th className="border-r py-2 text-md">orderId</th>
                                                <th className="border-r py-2 text-md">Status</th>
                                                <th className="border-r py-2 text-md">Price</th>
                                                <th className="border-r py-2 text-md">Level</th>
                                                <th className="border-r py-2 text-md">Kar</th>
                                                <th className="border-r py-2 text-md">Stop %</th>
                                                <th className="border-r py-2 text-md">per %</th>
                                                <th className="border-r py-2 text-md">%</th>
                                                <th className="border-r py-2 text-md">Hedef %</th>
                                                <th className="border-r py-2 text-md">Max %</th>
                                                
                                                
                                                <th className="border-r py-2 text-md">QTY</th>
                                                <th className="border-r py-2 text-md">SELL</th>
                                        </thead>
                                        <tbody className="bg-white divide-y divide-gray-200" style={{'font-family': 'monospace'}}>
                                            {this.state.df_shorts.map((trade, index) => {
                                                return(
                                                    <tr key={index} className='border'>
                                                       <td className='border-r'>{trade.symbol} </td>
                                                        <td className='border-r'>{new Date(trade.step1_open_date*1000).toLocaleString('tr-TR')}</td>
                                                        <td className='border-r'>{trade.step1_orderId} </td>
                                                        <td className='border-r'>{trade.step1_status}</td>
                                                        <td className='border-r'>{trade.step1_price}</td>
                                                        <td className='border-r'>{trade.level}</td>
                                                        <td className='border-r font-weight-bold'>{(trade.avg_price * trade.percent/100 * trade.step1_executedQty).toFixed(3) } </td>
                                                        <td className='border-r'>{parseFloat(trade.stop_percent).toFixed(2)}</td>
                                                        <td className='border-r'>{parseFloat(trade.per_percent).toFixed(2)}</td>
                                                        <td className={`border-r ${Math.fround(trade.percent) > Math.fround(0.0)?' bg-green-400': ' bg-red-500' }`}>{parseFloat(trade.percent).toFixed(2)} </td>
                                                        <td className='border-r'>{parseFloat(trade.sell_percent).toFixed(2)}</td>
                                                        <td className='border-r'>{parseFloat(trade.percent_max).toFixed(2)}</td>
                                                        <td className='border-r'>{(trade.step1_executedQty * trade.avg_price).toFixed(0)} $</td>
                                                        <td className='border-r flex justify-end '>
                                                        <div class="m-3 boder cursor-pointer" onClick={() => {
                                                                    this.socket.emit('arti', {symbol: trade.symbol, id:trade.step1_orderId , period:trade.period, price:0, quantity: parseFloat(trade.step1_executedQty)})
                                                                }}>+++</div>
                                                            <div class="m-3 boder cursor-pointer" onClick={() => {
                                                                    this.socket.emit('eksi', {symbol: trade.symbol, id:trade.step1_orderId , period:trade.period, price:0, quantity: parseFloat(trade.step1_executedQty)})
                                                                }}>---</div>
                                                            <div className='border text-red-700 px-0.5 rounded-md border-red-300 my-1 w-24 text-center bg-red-200 hover:bg-red-300 cursor-pointer' 
                                                                onClick={() => {
                                                                    this.socket.emit('del_short', {symbol: trade.symbol, id:trade.step1_orderId , period:trade.period, price:0, quantity: parseFloat(trade.step1_executedQty)})
                                                                }}>Sil</div>
                                                            <div className='border text-red-700 px-0.5 rounded-md border-red-300 my-1 w-24 text-center bg-red-200 hover:bg-red-300 cursor-pointer' 
                                                                onClick={() => {
                                                                    this.socket.emit('close_short', {symbol: trade.symbol, id:trade.step1_orderId , period:trade.period, price:0, quantity: parseFloat(trade.step1_executedQty)})
                                                                }}>KAPAT</div>
                                                        </td>
                                                    </tr>
                                                )
                                            })}     
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                        <div className='sm:grid-cols-2 mt-5 grid grid-cols-1 gap-10 w-full text-md'>
                            <div>
                                <div className='text-md mt-5'>
                                    CLOSED LONGS ({this.state.df_long_closeds.length})
                                </div>
                                <div className="shadow  border-b border-gray-200 sm:rounded-lg">
                                    <table className="min-w-full divide-y divide-gray-200">
                                        <thead className="bg-gray-50">
                                            <tr className="border">
                                                <th className="border-r py-2 text-md">Symbol</th>
                                                <th className="border-r py-2 text-md">Date</th>
                                                <th className="border-r py-2 text-md">orderId</th>
                                                <th className="border-r py-2 text-md">Status</th>
                                                <th className="border-r py-2 text-md">Buy</th>
                                                <th className="border-r py-2 text-md">Sell</th>
                                                <th className="border-r py-2 text-md">Kar</th>
                                                <th className="border-r py-2 text-md">Profit %</th>
                                                <th className="border-r py-2 text-md">Profit Max</th>
                                                <th className="border-r py-2 text-md">QTY</th>
                                            </tr>
                                        </thead>
                                        <tbody className="bg-white divide-y divide-gray-200" style={{'font-family': 'monospace'}}>
                                            {this.state.df_long_closeds.map((trade, index) => {
                                                return(
                                                    <tr key={index} className='border'>
                                                        <td className='border-r'>{trade.symbol}</td>
                                                        <td className='border-r'>{new Date(trade.step1_open_date*1000).toLocaleString('tr-TR')} - {new Date(trade.step2_close_date*1000).toLocaleTimeString('tr-TR')}</td>
                                                        <td className='border-r'>{trade.step1_orderId}</td>
                                                        <td className='border-r'>{trade.step1_status}</td>
                                                        <td className='border-r'>{trade.step1_price.toFixed(4)}</td>
                                                        <td className='border-r'>{trade.step2_status == "FILLED"?trade.step2_price.toFixed(4):'-'}</td>
                                                        <td className='border-r '><span className='font-bold'>{(trade.avg_price * trade.percent/100 * trade.step1_executedQty).toFixed(3) }</span>$ </td>
                                                        <td className='border-r'>{parseFloat(trade.percent).toFixed(2)}</td>
                                                        <td className='border-r'>{parseFloat(trade.percent_max).toFixed(2)}</td>
                                                        <td className='border-r'>{(trade.step1_executedQty * trade.avg_price).toFixed(0)} $</td>
                                                        
                                                       
                                                    </tr>
                                                )
                                            })}     
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                            <div>
                                <div className=' mt-5'>
                                    CLOSED SHORTS ({this.state.df_short_closeds.length})
                                </div>
                                <div className="shadow  border-b border-gray-200 sm:rounded-lg">
                                <table className="min-w-full divide-y divide-gray-200">
                                        <thead className="bg-gray-50">
                                            <tr className="border">
                                                <th className="border-r py-2 text-md">Symbol</th>
                                                <th className="border-r py-2 text-md">Date</th>
                                                <th className="border-r py-2 text-md">orderId</th>
                                                <th className="border-r py-2 text-md">Status</th>
                                                <th className="border-r py-2 text-md">Buy</th>
                                                <th className="border-r py-2 text-md">Sell</th>
                                                <th className="border-r py-2 text-md">Kar</th>
                                                <th className="border-r py-2 text-md">Profit %</th>
                                                <th className="border-r py-2 text-md">Profit Max</th>
                                                <th className="border-r py-2 text-md">QTY</th>
                                            </tr>
                                        </thead>
                                        <tbody className="bg-white divide-y divide-gray-200" style={{'font-family': 'monospace'}}>
                                            {this.state.df_short_closeds.map((trade, index) => {
                                                return(
                                                    <tr key={index} className='border'>
                                                        <td className='border-r'>{trade.symbol}</td>
                                                        <td className='border-r'>{new Date(trade.step1_open_date*1000).toLocaleString('tr-TR')} - {new Date(trade.step2_close_date*1000).toLocaleTimeString('tr-TR')}</td>
                                                        <td className='border-r'>{trade.step1_orderId}</td>
                                                        <td className='border-r'>{trade.step1_status}</td>
                                                        <td className='border-r'>{trade.step1_price.toFixed(4)}</td>
                                                        <td className='border-r'>{trade.step2_status == "FILLED"?trade.step2_price.toFixed(4):'-'}</td>
                                                        <td className='border-r '><span className='font-bold'>{(trade.avg_price * trade.percent/100 * trade.step1_executedQty).toFixed(3) }</span>$ </td>
                                                        <td className='border-r'>{parseFloat(trade.percent).toFixed(2)}</td>
                                                        <td className='border-r'>{parseFloat(trade.percent_max).toFixed(2)}</td>
                                                        <td className='border-r'>{(trade.step1_executedQty * trade.avg_price).toFixed(0)} $</td>
                                                       
                                                    </tr>
                                                )
                                            })}     
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                        
                    </div>
                </div>)}
            </div>
        )
    }
}
export default Trades;