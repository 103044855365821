import React from 'react';
import { LineChart, Line, Bar, ComposedChart, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';

function Macd(props) {
    const { candles } = props;
    const data = candles.map((candle, index) => {
        return {macd: parseFloat(candle.outMACD), S: parseFloat(candle.outMACDSignal), B:parseFloat(candle.outMACDHist), Z:0}
    });

    return (
        <ComposedChart width={150} height={120} data={data} margin={{top: 10,right: 20,bottom: 0,left: 0}} domain={['dataMin', 'dataMax']} >
            <CartesianGrid strokeDasharray="3 3" />
            <YAxis interval="preserveEnd" tick={{fontSize: 10}} stro />
            <XAxis tick={{fontSize: 10}} />
            <Tooltip />
            <Bar dataKey="B" barSize={10} fill="#0ab300" />
            <Line type="monotone" dataKey="macd" stroke="#e91e63" />
            <Line type="monotone" dataKey="S" stroke="#1281ca" />
            <Line type="monotone" dataKey="Z" stroke="#999" />
            
        </ComposedChart>
    );
}

export default Macd;